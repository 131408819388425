// actions for user data such as login/logout, user coupons , etc.

import { message, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";

// resets active coupons
export const clearActiveCoupon = () => {
  return {
    type: "CLEAR_ACTIVE_COUPON",
  };
};

// resets user, authentication status, and logout redirect status
export const logoutUser = () => {
  return {
    type: "LOGOUT_USER",
  };
};

// sets if user data is being fetched, called by fetchUserData
export const setFetchingUser = (status) => {
  return {
    type: "SET_FETCHING_USER",
    status,
  };
};

// sets logout redirect status
export const setRedirectAfterLogout = (status) => {
  return {
    type: "SET_REDIRECT_AFTER_LOGOUT",
    status,
  };
};

// sets if login is required at this time
export const setRequireLogin = (status) => {
  return {
    type: "SET_REQUIRE_LOGIN",
    status,
  };
};

// sets a user's class coupons, called by getClassCoupons
export const setUserCoupons = (coupons) => {
  return {
    type: "SET_USER_COUPONS",
    coupons,
  };
};

// sets user, called by fetchUserData
export const setUser = (user) => {
  return {
    type: "SET_USER",
    user,
  };
};

// sets if a user's coupons have been fetched, called by getClassCoupons
export const setCouponsReceived = (status) => {
  return {
    type: "SET_COUPONS_RECEIVED",
    status,
  };
};

// handles fetching all user-related data, called by fetchJWTTokens
export const fetchUserData = () => {
  return (dispatch) => {
    dispatch(setFetchingUser(true));
    return fetch("https://www.api.cubits.ai/api/v1/users/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((data) => {
        dispatch(setUser(data));
        dispatch(setFetchingUser(false));
        dispatch(getClassCoupons(data.id));
        notification.open({
          message: `Welcome Back, ${data["username"]}!`,
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        });
      })
      .catch((error) => {
        if (error.message == "TypeError: Failed to fetch") {
          message.error(
            "Unable to reach the server right now. Please try again later."
          );
        } else if (error.message == 401) {
          dispatch(getNewAccessToken(fetchUserData));
        }
      });
  };
};

// fetches new access token for a user who's token has timed out, called by fetchUserData
export const getNewAccessToken = (authenticatedFunc) => {
  localStorage.removeItem("access");
  return (dispatch) => {
    return fetch(`https://www.api.cubits.ai/api/v1/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: localStorage.getItem("refresh"),
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((data) => {
        if (data != null) {
          localStorage.setItem("access", data["access"]);
          dispatch(authenticatedFunc());
        }
      })
      .catch((error) => {
        if (error.message == 401) {
          localStorage.removeItem("refresh");
          console.log(
            "Your session has timed out. Please log in again."
          );
        }
      });
  };
};

// fetches access and refresh tokens
export const fetchJWTTokens = () => {
  return (dispatch) => {
    return fetch("https://www.api.cubits.ai/accounts/jwt-tokens/", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((data) => {
        if (data != null) {
          localStorage.setItem("refresh", data["refresh"]);
          localStorage.setItem("access", data["access"]);
          dispatch(fetchUserData());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// gets a user's class coupons, called by fetchUserData
export const getClassCoupons = (userID) => {
  return (dispatch) => {
    let url = `https://www.api.cubits.ai/api/v1/users/${userID}/scheduled-licenses/`;
    return fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setUserCoupons(data));
        dispatch(setCouponsReceived(true));
      })
      .catch((error) => console.log(error));
  };
};
