// actions for rendering graphs
import moment from "moment";

// sets selected class coupon
export const setSelectedCoupon = (couponData) => {
  return {
    type: "SET_SELECTED_COUPON",
    couponData,
  };
};

// sets scheduled accesses, called by getCouponData
export const setScheduledAccesses = (scheduledAccesses) => {
  return {
    type: "SET_SCHEDULED_ACCESSES",
    scheduledAccesses,
  };
};

// sets class roster, called by getCouponData
export const setRoster = (roster) => {
  return {
    type: "SET_ROSTER",
    roster,
  };
};

// handles fetching data for a specific coupon
export const getCouponData = (userID, couponID) => {
  return (dispatch) => {
    // Get data for scheduled accesses (videos)
    let url = `https://www.api.cubits.ai/api/v1/users/${userID}/scheduled-licenses/${couponID}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setScheduledAccesses(data.scheduled_accesses));
        delete data["scheduled_accesses"];
      })
      .catch((error) => console.log(error));

    // Get data for roster
    // Temporary - class dates is a 2 year period centered on today
    let coupon = "COS126SPRING2021";
    let classStartDate = moment(
      new Date().setDate(new Date().getDate() - 365)
    ).toISOString();
    let classEndDate = moment(
      new Date().setDate(new Date().getDate() + 365)
    ).toISOString();
    url = `https://www.api.cubits.ai/api/v1/dashboard/videowatchdata/home-page-roster-activity/?after=${classStartDate}&before=${classEndDate}&coupon=${coupon}`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setRoster(data));
        delete data["scheduled_accesses"];
      })
      .catch((error) => console.log(error));
  };
};

// adds selected video
export const addSelectedVideo = (selectedItem) => {
  return {
    type: "ADD_SELECTED_VIDEO",
    selectedItem,
  };
};

// removes selected video
export const removeSelectedVideo = (selectedItem) => {
  return {
    type: "REMOVE_SELECTED_VIDEO",
    selectedItem,
  };
};

// clears all selected videos
export const clearSelectedVideos = () => {
  return {
    type: "CLEAR_SELECTED_VIDEOS",
  };
};
