/******************************************************************
HomePageRosterActivity.js
Written by Adam Gamba, Summer 2021

Displays summary information about the class roster including a
histogram of student watch times, bar chart of top students, and
StudentGrid of students with zero watch time (past 7 days)
******************************************************************/

import React, { useState, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { connect } from "react-redux";
import { randomColor } from "randomcolor";
import moment from "moment";
import { Redirect } from "react-router";
import { Spin, Card, Tooltip, Empty, Collapse } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import StudentGrid from "./StudentGrid";
import RosterHistogram from "./RosterHistogram";
const { Panel } = Collapse;

const HomePageRosterActivity = (props) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [zeroRoster, setZeroRoster] = useState([]);

  const [topChartData, setTopChartData] = useState([]);
  const [topChartLabels, setTopChartLabels] = useState([]);
  const [topChartStudents, setTopChartStudents] = useState([]);
  const [histogramData, setHistogramData] = useState([]);

  // Formatting/styling options of graph
  const options = {
    responsive: true,
    indexAxis: "y",
    scales: {
      xAxes: [
        {
          ticks: { min: 0 },
          scaleLabel: {
            display: true,
            labelString: "Hours Watched",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: false,
            labelString: "Student ID",
            fontStyle: "bold",
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    onClick: (_, element) => {
      if (element[0] === undefined) return;
      let student = topChartStudents[element[0]._index];
      window.location = `./students/${student.studentId}`;
    },

    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          return `${tooltipItem.xLabel.toFixed(1)} hours`;
        },
      },
    },
  };

  // if class coupon is already set, fetch roster data for the given coupon
  useEffect(() => {
    if (dataLoaded) return;
    if (props.selectedCoupon != null) {
      let today = moment(new Date()).toISOString();
      let oneWeekAgo = moment(
        new Date().setDate(new Date().getDate() - 7)
      ).toISOString();

      // ! Uncomment to showcase an arbitrary week of semester
      // today = moment(
      //   new Date().setDate(new Date().getDate() - 150)
      // ).toISOString();
      // oneWeekAgo = moment(
      //   new Date().setDate(new Date().getDate() - 157)
      // ).toISOString();
      // !

      let coupon = "COS126SPRING2021";
      let url = `https://www.api.cubits.ai/api/v1/dashboard/videowatchdata/home-page-roster-activity/?after=${oneWeekAgo}&before=${today}&coupon=${coupon}`;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDataLoaded(true);
          setZeroRoster(data.filter((student) => student.hours === 0));

          formatData(data);
        })
        .catch((error) => console.log(error));
    }
  });

  // format fetch request data for input to roster graph
  const formatData = (data) => {
    // Assuming data is already sorted, if not, uncomment this block
    // data = data.sort((a, b) => {
    //   if (a.hours >= b.hours) return a;
    //   else return b;
    // });

    // Set histogram data to just include hours
    setHistogramData(data.map((x) => x.hours));

    // Take top 10 sorted students (by hours watched)
    data = data.slice(0, 10);

    // set labels as each student's username
    let labels = [];
    let hours = [];
    let students = [];
    data.forEach((student) => {
      labels.push(student.username);
      hours.push(student.hours);
      students.push(student);
    });

    // Update the data and labels in state
    setTopChartLabels(labels);
    setTopChartData(hours);
    setTopChartStudents(students);
  };

  if (!dataLoaded) {
    return (
      <div className="site-layout-content flex-center">
        <Spin size="large" />
      </div>
    );
  }
  // Empty if greatest value in sorted students is 0
  else if (topChartData[0] === 0) {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="No Roster Data Available (Past 7 Days)" />
      </div>
    );
  } else {
    let barChartData = {
      labels: topChartLabels,
      datasets: [
        {
          backgroundColor: "#5bd1d78F",
          barPercentage: 1,
          borderWidth: 4,
          data: topChartData,
        },
      ],
    };
    return (
      // Else, return data summaries
      <Card
        extra={
          <Tooltip
            placement="left"
            title="Histogram of student watch patterns of the past 7 days."
          >
            <QuestionCircleOutlined style={{ fontSize: "150%" }} />
          </Tooltip>
        }
        title={
          <h1
            className="flex-center"
            style={{
              fontSize: "24pt",
              fontWeight: "bold",
              margin: "5px",
            }}
          >
            Roster Data (Past 7 Days)
          </h1>
        }
        bordered={false}
      >
        {/* Render histogram of student watch tendencies of the past 7 days */}
        <RosterHistogram data={histogramData} />
        {/* <Card
          type="inner"
          title={
            <h2
              className="flex-center"
              style={{
                fontSize: "16pt",
                fontWeight: "bold",
                margin: "5px",
              }}
            >
              Star Students
            </h2>
          }
        > */}
        {/* Render collapsible bar chart of top 10 most active students */}
        <Collapse style={{ margin: "10px" }}>
          <Panel
            header={`Click to reveal top 10 most active students of the past 7 days`}
            extra={
              <Tooltip
                placement="left"
                title="Data from the top 10 most active students of the past 7 days. Click on a bar to visit a student's individual data page."
              >
                <QuestionCircleOutlined style={{ fontSize: "150%" }} />
              </Tooltip>
            }
          >
            <HorizontalBar
              data={barChartData}
              options={options}
              height={100}
            />
          </Panel>
        </Collapse>
        {/* </Card> */}
        {/* Render collapsible grid of inactive students of the past 7 days */}
        {zeroRoster.length > 0 ? (
          // <Card
          //   type="inner"
          //   title={
          //     <h2
          //       className="flex-center"
          //       style={{
          //         fontSize: "16pt",
          //         fontWeight: "bold",
          //         margin: "5px",
          //       }}
          //     >
          //       Zero Watch Time
          //     </h2>
          //   }
          // >
          <Collapse style={{ margin: "10px" }}>
            <Panel
              header={`Click to reveal ${zeroRoster.length} students with zero watch time in the past 7 days`}
              extra={
                <Tooltip
                  placement="left"
                  title="Data from all inactive students of the past 7 days. Click on a card to visit a student's individual data page."
                >
                  <QuestionCircleOutlined
                    style={{ fontSize: "150%" }}
                  />
                </Tooltip>
              }
            >
              <StudentGrid user={props.user} roster={zeroRoster} />
            </Panel>
          </Collapse>
        ) : // </Card>
        null}
      </Card>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    selectedCoupon: state.graphsReducer.selectedCoupon,
  };
};

export default connect(mapStateToProps)(
  withRouter(HomePageRosterActivity)
);
