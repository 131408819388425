import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  MenuOutlined,
  PlayCircleOutlined,
  HomeOutlined,
  PropertySafetyFilled,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Space, Select } from "antd";
import {
  getCouponData,
  setSelectedCoupon,
  clearSelectedVideos,
} from "../actions/graphs";
import { Component } from "react";

const { Option } = Select;

class ClassCouponDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }

  componentDidMount() {
    // generate default video dropdown content
    if (this.props.selectedCoupon) {
      this.props.getCouponData(
        this.props.user.id,
        this.props.selectedCoupon.id
      );
    }
  }

  componentDidUpdate(prevProps) {
    // if all coupons are returned, set first coupon as default selected coupon
    if (
      prevProps.userCoupons.length &&
      this.props.couponsReceived !== prevProps.couponsReceived &&
      this.props.userCoupons // Prevets undefined crash
    ) {
      this.props.setSelectedCoupon(this.props.userCoupons[0]);
      this.props.clearSelectedVideos();
    }
    // if selected coupon has changed, fetch coupon data for the new coupon
    if (this.props.selectedCoupon !== prevProps.selectedCoupon) {
      this.props.getCouponData(
        this.props.user.id,
        this.props.selectedCoupon.id
      );
      this.props.clearSelectedVideos();
    }
  }

  // change selected coupon
  handleOnSelect = (value, option) => {
    this.props.clearSelectedVideos();
    this.props.setSelectedCoupon(option.coupondata);
  };

  render() {
    if (
      this.props.isAuthenticated &&
      this.props.couponsReceived &&
      this.props.userCoupons.length &&
      this.props.selectedCoupon
    ) {
      return (
        <Space id="class-coupon-btn-wrapper">
          <Select
            defaultValue={this.props.selectedCoupon.id}
            onChange={this.handleOnSelect}
          >
            {this.props.userCoupons.map((coupon) => (
              <Option
                coupondata={coupon}
                key={coupon.id}
                value={coupon.id}
              >
                {coupon.label}
              </Option>
            ))}
          </Select>
        </Space>
      );
    } else {
      return (
        <Select
          disabled={true}
          placeholder={"No Active Classes"}
        ></Select>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    isAuthenticated: state.userReducer.isAuthenticated,
    userCoupons: state.userReducer.userCoupons,
    couponsReceived: state.userReducer.couponsReceived,
    selectedCoupon: state.graphsReducer.selectedCoupon,
  };
};

export default connect(mapStateToProps, {
  getCouponData,
  setSelectedCoupon,
  clearSelectedVideos,
})(ClassCouponDropdown);
