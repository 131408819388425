/******************************************************************
VideoDisplay.js
Written by Adam Gamba, Summer 2021

Displays requested video information on individual video page, including
embedded video and heatmap of watch time per minute bucket
******************************************************************/

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import VideoHeatMap from "./VideoHeatMap";
import "./Home.css";
import { Empty, Spin } from "antd";
import courseList from "../course_list.json";

// VideoInfoCard code commented out - for use later
const VideoDisplay = (props) => {
  // Example selectedObject: {"collectionID":5,"collection":"Computer
  // Science: An Interdisciplinary
  // Approach","moduleID":8,"module":"Conditionals &
  // Loops","videoID":18,"video":"Conditionals: The IF Statement"}

  const [totalViewers, setTotalViewers] = useState(0); // Number of unique viewes of video
  const [embedId, setEmbedId] = useState(""); // Youtube embed id
  const [selectedDates, setSelectedDates] = useState(
    props.selectedDates
  );
  // Heatmap data and labels
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded || props.numStudents === 0) return;
    // Get data for video
    let coupon = "COS126SPRING2021";
    let url = `https://www.api.cubits.ai/api/v1/dashboard/videowatchdata/video-retention/?collection=5&module=${props.selectedObject.moduleID}&video=${props.selectedObject.videoID}&after=${props.selectedDates[0]}&before=${props.selectedDates[1]}&coupon=${coupon}`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => response.json())
      .then((data) => setState(data))
      .catch((error) => console.log(error));
  });

  useEffect(() => {
    if (
      props.selectedDates[0] !== selectedDates[0] ||
      props.selectedDates[1] !== selectedDates[1]
    ) {
      setSelectedDates([
        props.selectedDates[0],
        props.selectedDates[1],
      ]);

      let coupon = "COS126SPRING2021";
      let url = `https://www.api.cubits.ai/api/v1/dashboard/videowatchdata/video-retention/?collection=5&module=${props.selectedObject.moduleID}&video=${props.selectedObject.videoID}&after=${props.selectedDates[0]}&before=${props.selectedDates[1]}&coupon=${coupon}`;

      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      })
        .then((response) => response.json())
        .then((data) => setState(data))
        .catch((error) => console.log(error));
    }
  });

  const formattingFunction = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  };

  const setState = (data) => {
    let videoBuckets = [];
    let counts = [];
    const NUM_STUDENTS = props.numStudents;
    data["buckets"].forEach((item) => {
      videoBuckets.push(formattingFunction(item["bucket"]));
      counts.push(item["unique_viewers"] / NUM_STUDENTS);
    });

    setTotalViewers(data["total_viewers"]);

    // Find embedId using course.json
    setEmbedId(
      courseList.filter(
        (x) => x.videoTitle === props.selectedObject.video
      )[0].embedID
    );

    setChartLabels(videoBuckets);
    // Convert to % and round to integer
    setChartData(counts.map((x) => Math.round(x * 100)));
    setIsLoaded(true);

    // Update contents of info card, after data is fetched
    // cardTabContents: [
    //   <p>[DESCRIPTION HERE]</p>,
    //   <p>
    //     Total Unique Viewers: {totalViewers} <br />
    //     Video Length: ____ <br />
    //     Pie chart of Video watchspeeds (1x, 2x, etc) <br />
    //     Date posted <br />
    //     % Student who watched the bulk of it <br />
    //     Likes, Dislikes, Comments, Questions (possibly in another tab)
    //     <br />I wanna include some smaller graphs within this section
    //     that are only loaded when the 'stats' tab is clicked, not to
    //     overrun the loading process like the previous screen that was
    //     all pie charts <br />
    //     Some other ideas: <br />
    //     -Stats on % correct on each video question (could be this tab
    //     or another) <br />
    //     -Average watch speed for each minute of video (could show the
    //     instructor what parts of the video are either boring or not
    //     paid as much attention due to higher video speed)
    //   </p>]
  };

  // Update state with chosen tab of current video
  // const onTabChange = (tab) => {
  //   this.setState({ currentCardTab: tab });
  // };

  // Card tab keys and names
  // Keys are indices and tabs are displayed names
  // const cardTabList = [
  //   {
  //     key: 0,
  //     tab: "Description",
  //   },
  //   {
  //     key: 1,
  //     tab: "Stats",
  //   },
  // Could inclue grade tab, timestamped comments/questions and more in the future
  // ];

  const videoHeight = 500;

  if (!isLoaded) {
    return (
      <div className="site-layout-content flex-center">
        <Spin size="large" />
      </div>
    );
  } else if (chartData.length === 0) {
    return (
      <section>
        <h2
          className="flex-center"
          style={{
            fontSize: "24pt",
            fontWeight: "bold",
            margin: "5px",
          }}
        >
          {` ${props.selectedObject.module}:
          ${props.selectedObject.video}`}
        </h2>
        <h3 className="flex-center">
          Total Unique Viewers: {totalViewers}
        </h3>
        <hr />
        <div className="flex-center">
          {/* Embedded YouTube video display */}
          <iframe
            width="80%"
            height={videoHeight + "px"}
            src={`https://www.youtube.com/embed/${embedId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <Empty
          description={`No Watch Data for Selected Period`}
          style={{ padding: "40px" }}
        />
        ;
      </section>
    );
  }
  return (
    <section>
      <h2
        className="flex-center"
        style={{
          fontSize: "24pt",
          fontWeight: "bold",
          margin: "5px",
        }}
      >
        {` ${props.selectedObject.module}:
          ${props.selectedObject.video}`}
      </h2>
      <h3 className="flex-center">
        Total Unique Viewers: {totalViewers}
      </h3>
      <hr />

      <div className="flex-center">
        {/* Embedded YouTube video display */}
        <iframe
          width="80%"
          height={videoHeight + "px"}
          src={`https://www.youtube.com/embed/${embedId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        {/* Info card row */}
        {/* <Card
            title={`${this.props.selectedObject.module}:
          ${this.props.selectedObject.video}`}
            tabList={cardTabList}
            // Get 0, 1, or 2 (3-tab setup) from array
            activeTab={this.state.currentCardTab}
            onTabChange={(tab) => {
              this.onTabChange(tab);
            }}
            hoverable
            bodyStyle={{
              overflow: "auto",
              height: "100%",
              width: "80vw",
            }}
            style={{
              marginTop: "1%",
            }}
          >
            {this.state.cardTabContents[this.state.currentCardTab]}
          </Card> */}
      </div>
      <div>
        {/* Heat map of video watch times */}
        <VideoHeatMap
          labels={chartLabels}
          data={chartData}
          video={props.selectedObject}
          width="80%"
          height="200px"
          homePage={false}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    numStudents: state.graphsReducer.numStudents,
    // selectedCoupon: state.graphsReducer.selectedCoupon,
    // roster: state.graphsReducer.roster,
  };
};

export default connect(mapStateToProps)(VideoDisplay);
