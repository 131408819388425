/******************************************************************
StudentCard.js
Written by Adam Gamba, Summer 2021

Single student informational card which links to their individual data
page (for admins)
******************************************************************/

import React from "react";
import { connect } from "react-redux";
import { Card } from "antd";
import { Link } from "react-router-dom";
import "./Home.css";

// Info Card for individual student
const StudentCard = (props) => {
  const student = props.student;

  // Two functions used to hash a string to a random color
  function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }
  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  }
  // Resulting hashed color
  let hashedColorString = intToRGB(hashCode(props.student.username));

  if (props.user.isContentCreator) {
    return (
      <span>
        {/* Each student card links to their personal data page */}
        <Link to={`/students/${student.studentId}`}>
          <Card
            title={`${student.username} (ID ${student.studentId})`}
            hoverable
            headStyle={{
              backgroundColor: "#FAFAFA",
              textAlign: "center",
            }}
            style={{
              border: `7px ridge #${hashedColorString}8F`,
              boxShadow: "2px 2px 2px #888888",
              margin: "5px",
            }}
          >
            Hours Watched: {/* Display rounded # of hours */}
            {student.hours.toFixed(1) % 1 === 0
              ? Math.round(student.hours)
              : student.hours.toFixed(1)}
          </Card>
        </Link>
      </span>
    );
  } else
    return (
      <span>
        {/* Cards do not link to student pages for a non-admin user */}
        <Card
          title={`${student.username}`}
          headStyle={{
            backgroundColor: "#FAFAFA",
            textAlign: "center",
          }}
          style={{
            border: `7px ridge #${hashedColorString}8F`,
            boxShadow: "2px 2px 2px #888888",
            margin: "5px",
          }}
        ></Card>
      </span>
    );
};

const mapStateToProps = (state) => {
  return {
    roster: state.graphsReducer.roster,
    user: state.userReducer.user,
  };
};
export default connect(mapStateToProps)(StudentCard);
