/******************************************************************
HomePage.js
Written by Adam Gamba, Summer 2021

Displays summary class information/graphs, acts as landing page
******************************************************************/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import HomePageVideoActivity from "./HomePageVideoActivity";
import HomePageRosterActivity from "./HomePageRosterActivity";
import HomePageCalendar from "./HomePageCalendar";
import course from "../course.json";
import moment from "moment";
import { Spin, Empty } from "antd";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Home.css";

const HomePage = (props) => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded || props.user === null) {
      return;
    }

    // Else fetch API
    setIsLoaded(true);
    let url = `https://www.api.cubits.ai/api/v1/users/${props.user.id}/scheduled-licenses/4/`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((data) => {
        let eventsList = [];
        // Example Module of scheduled accesses:
        /* {access_from: "2021-02-01T05:00:00"
            access_until: "2021-05-31T03:59:00"
            collection: 5
            collection_title: "Computer Science: An Interdisciplinary Approach"
            id: 32
            module: 18
            module_title: "Searching & Sorting"
            videos: (5) [{…}, {…}, {…}, {…}, {…}]} */
        for (let module of data.scheduled_accesses) {
          eventsList.push({
            title: `Module: ${module.module_title}`,
            start: moment(module.access_from),
            end: moment(module.access_until),
            allDay: true,
            // Extra data
            resource: { moduleTitle: module.module_title },
          });
        }
        setCalendarEvents(eventsList);
      })
      .catch((error) => console.log(error));
  });

  // First check if user is logged in, then load data if yes
  if (!props.isAuthenticated) {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="Not authenticated. Please log in or sign up above." />
      </div>
    );
  } else if (!isLoaded || props.user === null) {
    return (
      <div className="site-layout-content flex-center">
        <Spin size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <div className="site-layout-content">
          {/* Meta class info */}
          <h1
            className="flex-center"
            style={{
              fontSize: "32pt",
              fontWeight: "bold",
              margin: "5px",
            }}
          >
            {course.collection_name}
          </h1>
          <section
            style={{
              marginBottom: "25px",
            }}
          >
            <h2 className="flex-center" style={{ fontSize: "16pt" }}>
              {course.author}
            </h2>
            <div className="flex-center">
              <p
                style={{
                  fontSize: "14pt",
                  width: "70%",
                  textIndent: "50px",
                  textAlign: "justify",
                }}
              >
                {course.long_description}
              </p>
            </div>
          </section>

          {/* <div> */}
          {/* Intro Video */}
          {/* <iframe
              width="100%"
              height="500px"
              src={`https://www.youtube.com/embed/${course.intro}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            /> */}
          {/* </div> */}

          {/* Home page video activity */}
          <section
            style={{
              border: "7px ridge #5bd1d7",
              margin: "20px",
              boxShadow: "4px 4px 4px #888888",
            }}
          >
            <HomePageVideoActivity />
          </section>

          {/* Home page roster activity (for admins only) */}
          {props.user.isContentCreator ? (
            <div>
              <section
                style={{
                  border: "7px ridge #5bd1d7",
                  margin: "20px",
                  boxShadow: "4px 4px 4px #888888",
                }}
              >
                <HomePageRosterActivity user={props.user} />
              </section>
            </div>
          ) : null}

          {/* Display calendar (or spinner if loading) with dates from scheduled accesses fetch */}
          <section
            style={{
              border: "7px ridge #5bd1d7",
              margin: "20px",
              boxShadow: "4px 4px 4px #888888",
            }}
          >
            <h1
              className="flex-center"
              style={{
                fontSize: "24pt",
                fontWeight: "bold",
                margin: "20px 0 20px 0",
              }}
            >
              Modules Calendar
            </h1>
            <hr
              style={{
                border: "1px solid #FAFAFA",
                margin: "0 0 -60px 0",
              }}
            />
            <div
              className="flex-center"
              style={{
                height: "800px",
                width: "100%",
              }}
            >
              {calendarEvents.length === 0 ? (
                <Spin size="large" />
              ) : (
                <div
                  style={{
                    height: "75%",
                    width: "75%",
                    backgroundColor: "white",
                  }}
                >
                  <HomePageCalendar calendarEvents={calendarEvents} />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    isAuthenticated: state.userReducer.isAuthenticated,
  };
};
export default connect(mapStateToProps)(HomePage);
