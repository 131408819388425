/******************************************************************
CheckboxGrid.js
Written by Adam Gamba, Summer 2021

Used to display a grid of checkboxes, dashes, or Xs depending on a
student's watched videos
******************************************************************/

import courseList from "../course_list.json";
import {
  CheckSquareOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import { Link } from "react-router-dom";

const CheckboxGrid = (props) => {
  // List of unique module Ids
  const modulesList = [...new Set(courseList.map((x) => x.moduleID))];

  if (props.watchedVideos === undefined) {
    return <Spin />;
  } else
    return (
      <div
        style={{
          backgroundColor: "#FAFAFA",
          padding: "20px",
          boxShadow: "4px 4px 4px #888888",
          width: "50%",
          margin: "auto",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {modulesList.map((moduleId) => {
          let thisModule = courseList.filter(
            (x) => x.moduleID === moduleId
          );
          return (
            <span className="flex-center">
              <span style={{ marginRight: "5px" }}>
                Module {moduleId}:{" "}
              </span>
              <span style={{ marginLeft: "5px" }}>
                {thisModule.map((video) => {
                  // Determine if student has watched this particular
                  // video
                  let match = undefined;
                  let percentWatched = 0;
                  if (props.watchedVideos.hasOwnProperty([moduleId])) {
                    match = props.watchedVideos[moduleId].filter(
                      (x) => video.videoTitle === x.videoTitle
                    );
                    // Old: hasWatched = match.length > 0;
                  }
                  if (match !== undefined && match.length > 0) {
                    match = match[0];
                    // Deals with private/unlisted videos (0 video duration)
                    if (match.videoDuration === 0) {
                      match.videoDuration = match.data.length * 60;
                    }
                    // Amount watched / video duration
                    percentWatched =
                      (match.dataSum * 10) / match.videoDuration;
                  }

                  // Old code which ONLY uses X for zero watch time and
                  // check for non-zero
                  // let icon = null;
                  // if (hasWatched) {
                  //   icon = (
                  //     <CheckSquareOutlined
                  //       style={{
                  //         color: "green",
                  //         fontSize: "24px",
                  //       }}
                  //     />
                  //   );
                  // } else {
                  //   icon = (
                  //     <CloseSquareOutlined
                  //       style={{ color: "red", fontSize: "24px" }}
                  //     />
                  //   );
                  // }

                  // Sets icon to either X, dash, or check depending on
                  // the percent watched and given thresholds
                  let icon = null;
                  if (percentWatched < 0.25)
                    icon = (
                      <CloseSquareOutlined
                        style={{ color: "red", fontSize: "24px" }}
                      />
                    );
                  else if (percentWatched < 0.45) {
                    icon = (
                      <MinusSquareOutlined
                        style={{
                          color: "orange",
                          fontSize: "24px",
                        }}
                      />
                    );
                  } else {
                    icon = (
                      <CheckSquareOutlined
                        style={{
                          color: "green",
                          fontSize: "24px",
                        }}
                      />
                    );
                  }
                  //*

                  // Return green checkbox for yes, red X for no
                  // Include links to video pages and tooltips with
                  // video titles
                  return (
                    <Tooltip title={video.videoTitle}>
                      <Link
                        to={`/videos/${video.moduleID}/${video.videoID}`}
                      >
                        {icon}
                      </Link>
                    </Tooltip>
                  );
                })}
              </span>
            </span>
          );
        })}
        <section style={{ marginTop: "10px" }}>
          <div className="flex-center" style={{ margin: "5px" }}>
            <CheckSquareOutlined
              style={{
                color: "green",
                fontSize: "24px",
              }}
            />
            = Fully Watched (50-100%)
          </div>
          <div className="flex-center" style={{ margin: "5px" }}>
            <MinusSquareOutlined
              style={{
                color: "orange",
                fontSize: "24px",
              }}
            />
            = Partially Watched (25-50%)
          </div>
          <div className="flex-center" style={{ margin: "5px" }}>
            <CloseSquareOutlined
              style={{ color: "red", fontSize: "24px" }}
            />
            = Not Watched (0-25%)
          </div>
        </section>
      </div>
    );
};

export default CheckboxGrid;
