/******************************************************************
HomePageVideoActivity.js
Written by Adam Gamba, Summer 2021

Displays summary information about the course videos including a
bar chart of the most watched videos of the past 7 days
******************************************************************/

import React, { useState, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { connect } from "react-redux";
import { randomColor } from "randomcolor";
import moment from "moment";
import { Redirect } from "react-router";
import { Spin, Card, Tooltip, Empty } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import VideoHeatMap from "./VideoHeatMap";
import courseList from "../course_list.json";

const HomePageVideoActivity = (props) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartModules, setChartModules] = useState([]);
  const [chartVideos, setChartVideos] = useState([]);
  const [chartDataSums, setChartDataSums] = useState([]);

  // Two functions used to hash a string to a random color
  function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }
  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  }

  // Formatting/styling options of graph
  const options = {
    responsive: true,
    indexAxis: "y",
    scales: {
      xAxes: [
        {
          ticks: { min: 0 },
          scaleLabel: {
            display: true,
            color: "blue",
            labelString: "Hours Watched",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: false,
            labelString: "Video Title",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      title: { text: "Modules" },
      display: true,
      onClick: (_, e) => window.open(`/videos/search/${e.text}`),
      labels: {
        // Dispalys module titles in legend
        generateLabels: () => {
          // Remove duplicates using Set
          let uniqueModules = [...new Set(chartModules)];
          return uniqueModules.map((title, i) => {
            return {
              text: title,
              // Color bars based on hash of module title
              fillStyle: `#${intToRGB(hashCode(title))}8F`,
            };
          });
        },
      },
    },
    onClick: (_, element) => {
      if (element[0] === undefined) return;
      let video = chartVideos[element[0]._index];
      window.location = `./videos/${video.moduleId}/${video.videoId}`;
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.xLabel.toFixed(1)} hours`;
        },
      },
    },
  };

  // if class coupon is already set, fetch roster data for the given coupon
  useEffect(() => {
    if (dataLoaded) return;
    if (props.selectedCoupon != null) {
      let today = moment(new Date()).toISOString();
      let oneWeekAgo = moment(
        new Date().setDate(new Date().getDate() - 7)
      ).toISOString();

      // ! Uncomment to showcase an arbitrary week of semester
      // today = moment(
      //   new Date().setDate(new Date().getDate() - 140)
      // ).toISOString();
      // oneWeekAgo = moment(
      //   new Date().setDate(new Date().getDate() - 147)
      // ).toISOString();
      // !

      let coupon = "COS126SPRING2021";
      let url = `https://www.api.cubits.ai/api/v1/dashboard/videowatchdata/home-page-video-activity/?after=${oneWeekAgo}&before=${today}&coupon=${coupon}`;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          formatData(data);
          setDataLoaded(true);
        })
        .catch((error) => console.log(error));
    }
  });

  // format fetch request data for input to video graph
  const formatData = (data) => {
    // Add total views property (dataSum - # of minute bucket data
    // entries) to all videos
    for (let key in data) {
      // Convert data points -> hours (num * 60s / 3600s/hr)
      // Each data point represents a student viewing for 1 minute
      data[key].dataSum = data[key].data.reduce((a, b) => a + b) / 60;
    }

    // Returns sorted (by total watch time, high-low) data array of vids
    let sortedData = Object.entries(data)
      // Sort by dataSum property
      .sort((a, b) => {
        return b[1].dataSum - a[1].dataSum;
      })
      // Take greatest 5 sorted values
      .slice(0, 10)
      // Convert entries [string, obj] format to just video
      // Add videoId as property
      .map((arr) => {
        arr[1].videoId = parseInt(arr[0]);
        return arr[1];
      });

    // set labels as each video's title
    let labels = [];
    let modules = [];
    let dataSums = [];
    let videos = [];

    sortedData.forEach((video) => {
      labels.push(video.videoTitle);
      modules.push(
        courseList.filter((x) => {
          return x.videoTitle === video.videoTitle;
        })[0].moduleTitle
      );
      dataSums.push(video.dataSum);
      videos.push(video);
    });
    // Update data and labels in state
    setChartLabels(labels);
    setChartModules(modules);
    setChartDataSums(dataSums);
    setChartVideos(videos);
  };

  if (!dataLoaded) {
    return (
      <div className="site-layout-content flex-center">
        <Spin size="large" />
      </div>
    );
  } else if (chartDataSums.length === 0) {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="No Video Data Available (Past 7 Days)" />
      </div>
    );
  } else {
    let barChartData = {
      labels: chartLabels,
      datasets: [
        {
          // Color bars based on hash of module title
          backgroundColor: chartModules.map((x) => {
            return `#${intToRGB(hashCode(x))}8F`;
          }),
          barPercentage: 1,
          borderWidth: 4,
          data: chartDataSums,
        },
      ],
    };
    return (
      // Else, return data summaries
      <Card
        extra={
          <Tooltip
            placement="left"
            title="Data from the top 10 most viewed videos of the past 7 days. Click on a bar to visit a video's individual data page."
          >
            <QuestionCircleOutlined style={{ fontSize: "150%" }} />
          </Tooltip>
        }
        title={
          <h1
            className="flex-center"
            style={{
              fontSize: "24pt",
              fontWeight: "bold",
              margin: "5px",
            }}
          >
            Video Data (Past 7 Days)
          </h1>
        }
        bordered={false}
      >
        {/* Return horizontal bar chart of top 5 video watch times of week */}
        <HorizontalBar
          data={barChartData}
          options={options}
          height={100}
        />
      </Card>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    selectedCoupon: state.graphsReducer.selectedCoupon,
  };
};

export default connect(mapStateToProps)(
  withRouter(HomePageVideoActivity)
);
