/******************************************************************
HomePageCalendar.js
Written by Adam Gamba, Summer 2021

Displays calendar of scheduled accesses for the course
******************************************************************/

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

// Setup the localizer by providing the moment
const localizer = momentLocalizer(moment);

const HomePageCalendar = (props) => {
  // Used to style the event bars
  const eventStyleGetter = (event, start, end, isSelected) => {
    // Style active modules in green, and inactive modules in white
    let today = new Date();
    let isActive = today > start && today < end;
    let style = isActive
      ? {
          borderRadius: "5px",
          backgroundColor: "#5bd1d7",
          fontWeight: "bold",
          color: "black",
          border: "1px solid black",
        }
      : {
          borderRadius: "5px",
          backgroundColor: "white",
          color: "black",
          border: "1px solid black",
        };
    return {
      style: style,
    };
  };

  // Return calendar with correct options
  return (
    <Calendar
      localizer={localizer}
      events={props.calendarEvents}
      startAccessor="start"
      endAccessor="end"
      // Link to correct module on video search page
      onSelectEvent={(e) => {
        window.location = `/videos/search/${e.resource.moduleTitle}`;
      }}
      // Two view modes: monthly and agenda/tasks
      views={{
        month: true,
        agenda: true,
      }}
      eventPropGetter={eventStyleGetter}
    />
  );
};

export default HomePageCalendar;
