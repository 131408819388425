/******************************************************************
RosterHistogram.js
Written by Adam Gamba, Summer 2021

Displays a histogram of student watch times
******************************************************************/

import { Bar } from "react-chartjs-2";
import { Spin, Card } from "antd";

const RosterHistogram = (props) => {
  // Formatting/styling options of graph
  const options = {
    responsive: true,
    indexAxis: "x",
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Time Watched",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Number of Students",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          return `${tooltipItem.yLabel} students`;
        },
      },
    },
  };

  // Calculates mean, given an array of numbers
  const getMean = (arr) => {
    return arr.reduce((acc, val) => acc + val, 0) / arr.length;
  };
  // Calculates population standard deviation, given an array of numbers
  const getSD = (arr) => {
    const mean = getMean(arr);
    return Math.sqrt(
      arr
        .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
        .reduce((acc, val) => acc + val, 0) / arr.length
    );
  };

  // Histogram bucket labels and frequencies
  const bucketLabels = [
    "0-15 min",
    "15-30 min",
    "30-45 min",
    "45-60 min",
    "60-75 min",
    "75-90 min",
    "90-105 min",
    "105-120 min",
    "120+ min",
  ];
  let bucketFrequencies = [];
  for (let hours = 0; hours <= 2; hours += 0.25) {
    if (hours === 2) {
      bucketFrequencies.push(
        props.data.filter((x) => x >= hours).length
      );
    } else {
      bucketFrequencies.push(
        props.data.filter((x) => x >= hours && x < hours + 0.25).length
      );
    }
  }

  // Data to be passed to <Bar />
  let histogramData = {
    labels: bucketLabels,
    datasets: [
      {
        backgroundColor: "#5bd1d78F",
        borderWidth: 4,
        data: bucketFrequencies,
      },
    ],
  };

  if (props.data === undefined) {
    return <Spin />;
  } else {
    return (
      <div>
        {/* Display stat summaries of data */}
        <div
          style={{
            backgroundColor: "#FAFAFA",
            padding: "20px",
            boxShadow: "4px 4px 4px #888888",
            width: "50%",
            margin: "auto",
            marginBottom: "20px",
          }}
        >
          Number of Students = {props.data.length}
          <br />
          Average Watch Time = {getMean(props.data).toFixed(2)} hours
          <br />
          Standard Deviation = {getSD(props.data).toFixed(2)} hours
        </div>
        {/* Render histogram with 9 buckets */}
        <Bar data={histogramData} options={options} height={100} />
      </div>
    );
  }
};

export default RosterHistogram;
