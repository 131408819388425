import React, { Component } from "react";
import { Layout, Menu, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  Link,
  withRouter,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import HomePage from "./components/HomePage";
import VideoHome from "./components/VideoHome";
import IndividualVideoPage from "./components/IndividualVideoPage";
import StudentHome from "./components/StudentHome";
import IndividualStudentPage from "./components/IndividualStudentPage";

import LoginLogoutButtons from "./components/LoginLogoutButtons.js";
import ClassCouponDropdown from "./components/ClassCouponDropdown";

import { fetchJWTTokens, fetchUserData } from "./actions/user.js";

import "./App.less";
import logo from "./assets/cubits-logo.png";

const { Header, Content, Footer } = Layout;

class App extends Component {
  componentDidMount() {
    // Notification testing
    // let showNotification = () => {
    //   let notif = new Notification("Title", {
    //     body: "body test",
    //   });
    // };
    // console.log(Notification.permission);
    // if (Notification.permission === "granted") {
    //   setTimeout(showNotification, 5000);
    // } else if (Notification.permission !== "denied") {
    //   Notification.requestPermission().then((permission) => {
    //     if (permission === "granted") {
    //       setTimeout(showNotification, 5000);
    //     }
    //   });
    // }

    // if there is no access token, fetch tokens
    if (localStorage.getItem("access") === null) {
      this.props.fetchJWTTokens();
    }
    // otherwise, fetch user data for that access token
    else if (localStorage.getItem("access") !== null) {
      this.props.fetchUserData();
    }
  }

  componentDidUpdate(prevProps) {
    // if the user has just logged in, fetch user data for that access token
    if (
      prevProps.requireLogin != this.props.requireLogin &&
      prevProps.requireLogin
    ) {
      this.props.fetchUserData();
    }
  }

  // Renders routes and homepage content
  render() {
    // Returns either "", "videos", or "students" for homepage, video
    // page, and student page, respectively
    const pathname = window.location.pathname.split("/")[1];

    return (
      <Layout className="layout">
        <Header id="header">
          <Link to="/" className="logo">
            <img className="logo-img" src={logo} /> cubits
          </Link>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ float: "left" }}
            // Deselects menu items when homepage is clicked
            selectedKeys={[pathname]}
          >
            <Menu.Item key="videos">
              <Link to="/videos">Videos</Link>
            </Menu.Item>
            <Menu.Item key="students">
              <Link to="/students">Students</Link>
            </Menu.Item>
          </Menu>
          {/* <Menu.Item key="test"> */}
          <ClassCouponDropdown
            className="loginlogout"
            user={this.props.user || ""}
          />
          {/* </Menu.Item> */}
          {/* <Menu.Item key="test2"> */}
          <LoginLogoutButtons
            className="loginlogout"
            user={this.props.user || ""}
          />{" "}
          {/* </Menu.Item> */}
        </Header>
        <Content
          className="site-layout"
          style={{ marginTop: 64, minHeight: "calc(100vh-134px)" }}
        >
          <Switch>
            {/* Home Page */}
            <Route path="/" exact component={HomePage} />
            {/* Video Home Page */}
            <Route path="/videos" exact component={VideoHome} />
            {/* Video Home Page with predetermined search */}
            <Route
              path="/videos/search/:searchTerm"
              exact
              component={VideoHome}
            />
            {/* Individual Video Page */}
            <Route
              path="/videos/:moduleID/:videoID"
              exact
              component={IndividualVideoPage}
            />
            {/* Student Home Page */}
            <Route path="/students" exact component={StudentHome} />
            {/* Student Home Page with predetermined search */}
            <Route
              path="/students/search/:searchTerm"
              exact
              component={StudentHome}
            />
            {/* Individual Student Page */}
            <Route
              path="/students/:studentID"
              exact
              component={IndividualStudentPage}
            />
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center" }}>CUbits ©2020</Footer>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    requireLogin: state.userReducer.requireLogin,
  };
};

export default connect(mapStateToProps, {
  fetchJWTTokens,
  fetchUserData,
})(withRouter(App));
