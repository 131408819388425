/******************************************************************
IndividualVideoPage.js
Written by Adam Gamba, Summer 2021

Individual video data page, includes date search filter, search buttons,
and VideoDisplay with requested information
******************************************************************/

import React, { useState } from "react";
import VideoDisplay from "./VideoDisplay";
import moduleVideoData from "../moduleVideoData.json";
import { DatePicker, Button, Empty } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

// Displays all available videos in array of videos
const IndividualVideoPage = (props) => {
  // Boolean values for buttons (default thisWeekSelected)
  let [thisWeekSelected, setThisWeekSelected] = useState(true);
  let [fullSemesterSelected, setFullSemesterSelected] = useState(false);

  // Format: [startDate, endDate], ex. [2021-03-01T21:40:35.219Z, ...]
  // Selects today as start, and 1 year ago today as end
  let [selectedDates, setSelectedDates] = useState([
    moment(new Date().setDate(new Date().getDate() - 7)),
    moment(new Date()),
  ]);
  // Used to remember the dates prior to "full semester" button click
  let [previousDates, setPreviousDates] = useState([]);

  const rangePickerOnChange = (dates, dateStrings) => {
    setFullSemesterSelected(false);
    setThisWeekSelected(false);
    setSelectedDates([dates[0], dates[1]]);
  };

  // Variables come from redux link ":var"
  let videoID = parseInt(props.match.params.videoID);
  let moduleID = parseInt(props.match.params.moduleID);

  if (localStorage.getItem("loggedIn") === "false") {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="Not authenticated. Please log in or sign up above." />
      </div>
    );
  } else
    return (
      <div className="site-layout-content">
        <section style={{ paddingBottom: "10px" }}>
          {/* Date picker for dates to filter video graphs */}
          <RangePicker
            value={selectedDates}
            format="MMM-DD-YYYY"
            onChange={rangePickerOnChange}
            allowClear={false}
            size="large"
          />

          {/* Button to set dates to most recent week */}
          <Button
            size="large"
            onClick={() => {
              if (thisWeekSelected) {
                setThisWeekSelected(false);
                setSelectedDates(previousDates);
              } else {
                setThisWeekSelected(true);
                setFullSemesterSelected(false);
                setPreviousDates(selectedDates);
                setSelectedDates([
                  moment(new Date().setDate(new Date().getDate() - 7)),
                  moment(new Date()),
                ]);
              }
            }}
            style={
              thisWeekSelected
                ? {
                    background: "#5bd1d7",
                    color: "white",
                  }
                : null
            }
          >
            Select Last 7 Days
          </Button>

          {/* Button to set dates to entire semester (earliest-latest dates) */}
          <Button
            size="large"
            onClick={() => {
              if (fullSemesterSelected) {
                setFullSemesterSelected(false);
                setSelectedDates(previousDates);
              } else {
                setFullSemesterSelected(true);
                setThisWeekSelected(false);
                setPreviousDates(selectedDates);
                setSelectedDates([
                  moment(
                    new Date().setDate(new Date().getDate() - 365)
                  ),
                  moment(new Date()),
                ]);
              }
            }}
            style={
              fullSemesterSelected
                ? {
                    background: "#5bd1d7",
                    color: "white",
                  }
                : null
            }
          >
            Select Full Semester
          </Button>
        </section>

        {/* Render display of single video */}
        <VideoDisplay
          // Get ids and titles of video/module
          selectedObject={{
            videoID: videoID,
            moduleID: moduleID,
            video: moduleVideoData[moduleID].videos.filter(
              (x) => x.id === videoID
            )[0].title,
            module: moduleVideoData[moduleID].module_title,
          }}
          // Use isoString format of date objects
          selectedDates={selectedDates.map((date) =>
            date.toISOString()
          )}
        />
      </div>
    );
};

export default IndividualVideoPage;
