/******************************************************************
StudentHome.js
Written by Adam Gamba, Summer 2021

Home page for students. Includes search, filters, and StudentGrid
******************************************************************/

import React, { useState, useEffect } from "react";
import StudentList from "./StudentGrid";
import "./Home.css";
import { connect } from "react-redux";
import { Row, Col, Input, Select, Spin, Empty } from "antd";

const { Search } = Input;
const { Option } = Select;

const StudentHome = (props) => {
  // Search term of search bar and function to set its value (can search
  // automatically using url)
  const [searchTerm, setSearchTerm] = useState(
    props.match.params.hasOwnProperty("searchTerm")
      ? props.match.params.searchTerm
      : ""
  );
  const [sortType, setSortType] = useState(sortAZ);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) return;
    if (props.roster.length > 0) setIsLoaded(true);
  });

  // First check if user is logged in, then load data if yes
  if (localStorage.getItem("loggedIn") === "false") {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="Not authenticated. Please log in or sign up above." />
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="site-layout-content flex-center">
        <Spin size="large" />
      </div>
    );
  } else
    return (
      <div className="site-layout-content">
        <Row style={{ paddingBottom: "1%", margin: "5px" }}>
          {/* antd search bar to search students */}
          <Col span={18}>
            <Search
              type="text"
              placeholder="Search Students"
              onChange={(e) => setSearchTerm(e.target.value)}
              size="large"
              allowClear
            />
          </Col>

          {/* Select Sorting Type */}
          <Col span={6}>
            <Select
              defaultValue={"sortAZ"}
              style={{ width: "100%" }}
              size="large"
              // Use switch statement to set state to current function
              onChange={(type) => {
                switch (type) {
                  case "sortAZ":
                    setSortType(sortAZ);
                    break;
                  case "sortZA":
                    setSortType(sortZA);
                    break;
                  case "sortIdAscending":
                    setSortType(sortIdAscending);
                    break;
                  case "sortIdDescending":
                    setSortType(sortIdDescending);
                    break;
                  case "sortHoursAscending":
                    setSortType(sortHoursAscending);
                    break;
                  case "sortHoursDescending":
                    setSortType(sortHoursDescending);
                    break;
                }
              }}
            >
              <Option value={"sortAZ"}>Username A-Z</Option>
              <Option value={"sortZA"}>Username Z-A</Option>

              {/* Only give option to sort by ID/hours to admins */}
              {props.user.isContentCreator ? (
                <Option value={"sortIdAscending"}>ID Ascending</Option>
              ) : null}
              {props.user.isContentCreator ? (
                <Option value={"sortIdDescending"}>
                  ID Descending
                </Option>
              ) : null}
              {props.user.isContentCreator ? (
                <Option value={"sortHoursAscending"}>
                  Hours Ascending
                </Option>
              ) : null}
              {props.user.isContentCreator ? (
                <Option value={"sortHoursDescending"}>
                  Hours Descending
                </Option>
              ) : null}
            </Select>
          </Col>
        </Row>

        {/* Maps list of students to individual StudentCards */}
        <StudentList
          // Filters props. based on search bar search term
          // Filters by ID too if searchTerm is a number
          user={props.user}
          roster={props.roster
            .filter((student) => {
              // Return true for students that match search term
              if (searchTerm === "") return true;
              else if (
                !isNaN(searchTerm) &&
                student.studentId === parseInt(searchTerm)
              ) {
                return true;
              } else if (
                student.username
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return true;
              }
            })
            // Sort type chosen from (currently 6) types in helpers below
            .sort(sortType)}
        />
      </div>
    );
};

// Sorting helper methods
// ? checking for undef saves us from error, not entirely sure why
const sortAZ = (a, b) => {
  if (a === undefined || b === undefined) return sortAZ;
  if (a.username > b.username) return 1;
  else if (a.username < b.username) return -1;
  else return 0;
};
const sortZA = (a, b) => {
  if (a === undefined || b === undefined) return sortZA;
  return sortAZ(b, a);
};
const sortIdAscending = (a, b) => {
  if (a === undefined || b === undefined) return sortIdAscending;
  if (a.studentId > b.studentId) return 1;
  else if (a.studentId < b.studentId) return -1;
  else return sortAZ(a, b);
};
const sortIdDescending = (a, b) => {
  if (a === undefined || b === undefined) return sortIdDescending;
  if (a.studentId > b.studentId) return -1;
  else if (a.studentId < b.studentId) return 1;
  else return sortAZ(a, b);
};
const sortHoursAscending = (a, b) => {
  if (a === undefined || b === undefined) return sortHoursAscending;
  if (a.hours > b.hours) return 1;
  else if (a.hours < b.hours) return -1;
  else return sortAZ(a, b);
};
const sortHoursDescending = (a, b) => {
  if (a === undefined || b === undefined) return sortHoursDescending;
  if (a.hours > b.hours) return -1;
  else if (a.hours < b.hours) return 1;
  else return sortAZ(a, b);
};

const mapStateToProps = (state) => {
  return {
    roster: state.graphsReducer.roster,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps)(StudentHome);
