import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Space } from "antd";
import { logoutUser } from "../actions/user";

const LoginLogoutButtons = ({ user, isAuthenticated, logoutUser }) => {
  // on menu click, logout user and return logout message plus redirect
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1":
        window.location.href =
          "https://www.api.cubits.ai/accounts/login/?next=https://www.cubits.ai/";
        return null;
      case "2":
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        message.loading("Logging out...");

        logoutUser();
        localStorage.setItem("loggedIn", "false");
        localStorage.setItem("userFetched", "false");

        return setTimeout(() => {
          message.success("You are now logged out");
          let oldPage = window.location.href;
          return (window.location = `https://www.api.cubits.ai/accounts/logout/?next=${oldPage}`);
        }, 3000);
      default:
        return;
    }
  };

  // logout menu for dropdown overlay
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<PlayCircleOutlined />}>
        Cubits Video
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} danger>
        Logout
      </Menu.Item>
    </Menu>
  );

  if (!isAuthenticated) {
    localStorage.setItem("loggedIn", "false");
    localStorage.setItem("userFetched", "false");
    return (
      // if user is not logged in, display sign up and login buttons
      <Space id="login-register-btn-wrapper" size="middle">
        <Button className="login-btn" type="primary">
          <a
            href={`https://www.api.cubits.ai/accounts/signup/?next=${window.location.href}`}
          >
            Sign Up
          </a>
        </Button>
        <Button className="login-btn" ghost>
          <a
            href={`https://www.api.cubits.ai/accounts/login/?next=${window.location.href}`}
          >
            Login
          </a>
        </Button>
      </Space>
    );
  } else {
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("userFetched", "true");
    // Links to home for admins, links to student page for students
    let linkTo = user.isContentCreator ? "/" : `/students/${user.id}`;
    return (
      // If user is logged in, display username dropdown with logout menu overlay
      <Space id="login-register-btn-wrapper">
        <Dropdown.Button
          overlay={menu}
          placement="bottomRight"
          icon={<MenuOutlined />}
        >
          <Link to={linkTo}>
            <Space>
              <UserOutlined />
              {user.username}
            </Space>
          </Link>
        </Dropdown.Button>
      </Space>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps, { logoutUser })(
  LoginLogoutButtons
);
