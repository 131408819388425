/******************************************************************
VideoGrid.js
Written by Adam Gamba, Summer 2021

Renders grid of video cards
******************************************************************/

import VideoCard from "./VideoCard";
import { Row, Col } from "antd";

// Displays all available videos in array of videos
const VideoGrid = ({ videos, sortType }) => {
  // Set number of columns based on screen width
  const NUM_OF_COLUMNS =
    window.innerWidth > 1000 ? 4 : window.innerWidth > 800 ? 3 : 2;

  // Renders list of videos as a grid with NUM_OF_COLUMNS columns
  // Make sure NUM_OF_COLUMNS is integer divisible by 24 to work
  if (sortType === "sortAZ" || sortType === "sortZA") {
    return (
      <Row>
        {videos.map((video, i) => {
          return (
            <Col span={24 / NUM_OF_COLUMNS}>
              <VideoCard key={i} video={video} displayModuleTitle />
            </Col>
          );
        })}
      </Row>
    );
  }
  // Else, sort by module
  else {
    // Format videos array into object, with keys being module IDs and
    // values being an array of videos in that module
    let formattedVideos = {};
    for (let video of videos) {
      let moduleId = video.moduleId;
      if (formattedVideos.hasOwnProperty(moduleId)) {
        formattedVideos[moduleId].push(video);
      } else {
        formattedVideos[moduleId] = [video];
      }
    }

    // Account for sorting method - module ascending or descending
    let valuesList = [];
    if (sortType === "sortModuleAscending") {
      valuesList = Object.values(formattedVideos);
    } else {
      valuesList = Object.values(formattedVideos).reverse();
    }
    return (
      // Renders list of videos as a grid with NUM_OF_COLUMNS columns
      // Make sure NUM_OF_COLUMNS is integer divisible by 24 to work
      <div>
        {valuesList.map((module, i) => {
          return (
            <div>
              <h2 className="module-titles">
                Module {module[0].moduleId} - {module[0].moduleTitle}
              </h2>
              <Row>
                {module.map((video, j) => (
                  <Col span={24 / NUM_OF_COLUMNS}>
                    <div>
                      <VideoCard module={module} video={video} />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
      </div>
    );
  }
};

export default VideoGrid;
