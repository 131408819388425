const initialState = {
  user: null,
  username: "",
  password: "",
  requireLogin: false,
  redirectAfterLogout: false,
  fetchingUser: false,
  isAuthenticated: false,
  userCoupons: [],
  couponsReceived: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_ACTIVE_COUPON":
      return Object.assign({}, state, { activeCoupons: null });
    case "LOGOUT_USER":
      return {
        user: null,
        isAuthenticated: false,
        redirectAfterLogout: true,
      };
    case "SET_ACTIVE_COUPON":
      return Object.assign({}, state, { activeCoupon: action.coupon });
    case "SET_FETCHING_USER":
      return Object.assign({}, state, { fetchingUser: action.status });
    case "SET_REDIRECT_AFTER_LOGOUT":
      return Object.assign({}, state, {
        redirectAfterLogout: action.status,
      });
    case "SET_REQUIRE_LOGIN":
      return Object.assign({}, state, { requireLogin: action.status });
    case "SET_USER_COUPONS":
      return Object.assign({}, state, { userCoupons: action.coupons });
    case "SET_USER":
      return Object.assign({}, state, {
        user: action.user,
        isAuthenticated: true,
      });
    case "SET_COUPONS_RECEIVED":
      return Object.assign({}, state, {
        couponsReceived: action.status,
      });
    default:
      return state;
  }
};
