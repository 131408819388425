/******************************************************************
StudentLineChart.js
Written by Adam Gamba, Summer 2021

Displays area/line chart of student watch time per minute bucket
******************************************************************/

import React from "react";
import { Line } from "react-chartjs-2";

const StudentLineChart = (props) => {
  // Example this.props.selectedVideo format
  // {title: "Basic Programming Concepts", data: Array(22)}
  // const [lineChartLabels, setLineChartLabels] = useState([]);

  // Two functions used to hash a string to a random color
  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }
  function intToRGB(i) {
    let c = (i & 0x00ffffff).toString(16).toUpperCase();
    if (c[0] === "F") c = "B" + c.substring(1); // Remove whites
    return "00000".substring(0, 6 - c.length) + c;
  }
  // Resulting hashed color
  let hashedColorString = intToRGB(
    hashCode(props.selectedVideo.videoTitle)
  );

  // Config options provided to line chart
  const options = {
    // Todo link to cuvids video instead of youtube
    // onClick: (event, element) => {
    // console.log("", this.props.selectedVideo, element[0]);
    // window.open(
    //   `https://www.youtube.com/watch?v=${
    //     this.props.selectedVideo.videoId
    //   }&t=${10}`
    // );
    // },

    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Minute of Video",
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Time Invested (s)",
          },
          gridLines: {
            display: true,
          },
          ticks: {
            // y scale is max of either 60 or max data value
            min: 0,
            max: Math.max(
              60,
              // Steps by 20 (no 70, 90, etc.)
              (() => {
                let maxOfArray = Math.max(...props.selectedVideo.data);
                if (maxOfArray % 2 === 0) return 10 * maxOfArray;
                return 10 * maxOfArray + 10;
              })()
            ),
          },
        },
      ],
    },
    title: {
      display: false,
      // position: "top",
      // lineHeight: 1,
      // text: this.props.selectedVideo.title,
      // fontSize: 20,
    },
    legend: {
      display: true,
      position: "right",
    },
  };

  const MAXIMUM_WIDTH_PERCENTAGE = 95;
  const SECONDS_IN_MINUTE = 60;

  // Data/styles to be passed to line chart
  let lineChartData = {
    labels: [],
    datasets: [
      {
        label: "Seconds watched",
        data: props.selectedVideo.data.map((x) => 10 * x),
        backgroundColor: `#${hashedColorString}40`,
        borderColor: `#${hashedColorString}FF`,
        borderWidth: 1,
      },
      {
        label: "Baseline (60s)",
        data: props.selectedVideo.data.map((x) => SECONDS_IN_MINUTE),
        fill: false,
        backgroundColor: `#f004`,
        borderColor: "#f00A",
        borderWidth: 1,
      },
    ],
  };

  // Prepend value to data to be the same as first value of array (fix
  // shift error of 1 from time buckets)
  lineChartData.datasets[0].data.unshift(
    lineChartData.datasets[0].data[0]
  );
  lineChartData.datasets[1].data.unshift(SECONDS_IN_MINUTE);

  // Create and set labels array
  lineChartData.labels = new Array(
    lineChartData.datasets[0].data.length
  )
    .fill(0)
    .map((_, index) => `${index}:00`);

  return (
    <div>
      <h3 style={{ fontSize: 20, fontWeight: "bold" }}>
        {props.selectedVideo.videoTitle}
      </h3>
      <div
        // constants here designed to minimize cases of screen scrolling
        // and stretch shorter videos to be longer, relatively
        style={{
          width: `${
            // Old method
            // (SECONDS_IN_MINUTE *
            //   lineChartData.datasets[0].data.length) /
            //   props.maxVideoDuration >
            // 0.8
            //   ? 95
            //   : (90 *
            //       WIDTH_SCALING_FACTOR *
            //       SECONDS_IN_MINUTE *
            //       lineChartData.datasets[0].data.length) /
            //     props.maxVideoDuration

            // New method
            (MAXIMUM_WIDTH_PERCENTAGE *
              // Uses function f(x) = log(9x + 1) to preferentially
              // increase width of shorter videos, and crosses the //
              // origin and (1,1)
              Math.log(
                (9 *
                  SECONDS_IN_MINUTE *
                  lineChartData.datasets[0].data.length) /
                  props.maxVideoDuration +
                  1
              )) /
            Math.log(10) // log base 10
          }%`,
        }}
      >
        <Line data={lineChartData} options={options} />
      </div>
    </div>
  );
};

export default StudentLineChart;
