const initialState = {
  selectedCoupon: null,
  scheduledAccesses: [],
  selectedVideos: [],
  selectedVideoIds: [],
  roster: [],
  numStudents: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_COUPON":
      return Object.assign({}, state, {
        selectedCoupon: action.couponData,
      });
    case "SET_SCHEDULED_ACCESSES":
      return Object.assign({}, state, {
        scheduledAccesses: action.scheduledAccesses,
      });
    case "SET_ROSTER":
      return Object.assign({}, state, {
        roster: action.roster,
        numStudents: action.roster.length,
        // filterdRoster: action.roster,
      });
    case "ADD_SELECTED_VIDEO":
      return Object.assign({}, state, {
        // spread previously selected videos and append newly selected video
        selectedVideos: [...state.selectedVideos, action.selectedItem],
        selectedVideoIds: [
          ...state.selectedVideoIds,
          action.selectedItem.videoID,
        ],
      });
    case "REMOVE_SELECTED_VIDEO":
      return Object.assign({}, state, {
        // remove selected video by filtering from previously selected videos
        selectedVideos: state.selectedVideos.filter(
          (itemData) => itemData.videoID != action.selectedItem.videoID
        ),
      });
    case "CLEAR_SELECTED_VIDEOS":
      return Object.assign({}, state, { selectedVideos: [] });

    default:
      return state;
  }
};
