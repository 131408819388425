/******************************************************************
VideoHome.js
Written by Adam Gamba, Summer 2021

Home page for videos. Includes search, filters, and VideoGrid
******************************************************************/

import React, { useState, useEffect } from "react";
import { Input, Select, Spin, Row, Col, Empty } from "antd";

import "./Home.css";
import { connect } from "react-redux";
import VideoGrid from "./VideoGrid.js";

const { Search } = Input;
const { Option } = Select;

const VideoHome = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  // List of video objects to be sent as props to VideoGrid
  const [videosList, setVideosList] = useState([]);
  // Search term of search bar and function to set its value (can search
  // automatically using url)
  const [searchTerm, setSearchTerm] = useState(
    props.match.params.hasOwnProperty("searchTerm")
      ? props.match.params.searchTerm
      : ""
  );
  const [sortType, setSortType] = useState(sortModuleAscending);
  const [sortTypeString, setSortTypeString] = useState(
    "sortModuleAscending"
  ); // passed to VideoGrid to determine whether to display modules

  useEffect(() => {
    if (props.scheduledAccesses.length === 0 || isLoaded) return;
    else setIsLoaded(true);
    // Create list of all video objects
    let list = [];
    props.scheduledAccesses.map((module) => {
      module.videos.map((video) => {
        list.push({
          videoId: video.id,
          videoTitle: video.title,
          moduleId: module.module,
          moduleTitle: module.module_title,
        });
      });
    });
    setVideosList(list);
  });

  // First check if user is logged in, then load data if yes
  if (localStorage.getItem("loggedIn") === "false") {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="Not authenticated. Please log in or sign up above." />
      </div>
    );
  } else if (!isLoaded || localStorage.getItem("loggedIn") === null) {
    return (
      <div className="site-layout-content flex-center">
        <Spin size="large" />
      </div>
    );
  } else {
    return (
      <div className="site-layout-content">
        <Row style={{ paddingBottom: "1%", margin: "5px" }}>
          {/* antd search bar to search videos */}
          <Col span={18}>
            <Search
              type="text"
              placeholder="Search Videos"
              onChange={(e) => setSearchTerm(e.target.value)}
              size="large"
              allowClear
            />
          </Col>

          {/* Select Sorting Type */}
          <Col span={6}>
            <Select
              defaultValue={"sortModuleAscending"}
              size="large"
              style={{ width: "100%" }}
              // Use switch statement to set state to current function
              onChange={(type) => {
                switch (type) {
                  case "sortAZ":
                    setSortType(sortAZ);
                    setSortTypeString("sortAZ");
                    break;
                  case "sortZA":
                    setSortType(sortZA);
                    setSortTypeString("sortZA");
                    break;
                  case "sortModuleAscending":
                    setSortType(sortModuleAscending);
                    setSortTypeString("sortModuleAscending");
                    break;
                  case "sortModuleDescending":
                    setSortType(sortModuleDescending);
                    setSortTypeString("sortModuleDescending");
                    break;
                }
              }}
            >
              <Option value={"sortModuleAscending"}>
                Module Ascending
              </Option>
              <Option value={"sortModuleDescending"}>
                Module Descending
              </Option>
              <Option value={"sortAZ"}>Title A-Z</Option>
              <Option value={"sortZA"}>Title Z-A</Option>
            </Select>
          </Col>
        </Row>

        {/* Sorted grid of videos to display, filtered and sorted */}
        <VideoGrid
          videos={videosList
            .filter((video) => {
              if (searchTerm === "") return true;
              else if (
                !isNaN(searchTerm) &&
                (video.videoId === parseInt(searchTerm) ||
                  video.moduleId === parseInt(searchTerm))
              ) {
                return true;
              } else if (
                video.videoTitle
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                video.moduleTitle
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return true;
              }
            })
            // Sort type chosen from (currently 4) types in helpers below
            .sort(sortType)}
          sortType={sortTypeString}
        />
      </div>
    );
  }
};

// Sorting helper methods
// ? undefined check saves from error, not entirely sure why
const sortAZ = (a, b) => {
  if (a === undefined || b === undefined) return sortAZ;
  if (a.videoTitle > b.videoTitle) return 1;
  else if (a.videoTitle < b.videoTitle) return -1;
  else return 0;
};
const sortZA = (a, b) => {
  if (a === undefined || b === undefined) return sortZA;
  return sortAZ(b, a);
};
// sortModuleAscending and sortModuleDescending are mostly the same here
// and are differentiated in the VideoGrid component
const sortModuleAscending = (a, b) => {
  if (a === undefined || b === undefined) return sortModuleAscending;
  if (a.videoId > b.videoId) return 1;
  else if (a.videoId < b.videoId) return -1;
  else return sortAZ(a, b);
};
const sortModuleDescending = (a, b) => {
  if (a === undefined || b === undefined) return sortModuleDescending;
  if (a.videoId > b.videoId) return 1;
  else if (a.videoId < b.videoId) return -1;
  else return sortAZ(a, b);
};

const mapStateToProps = (state) => {
  return {
    scheduledAccesses: state.graphsReducer.scheduledAccesses,
  };
};

export default connect(mapStateToProps)(VideoHome);
