/******************************************************************
VideoCard.js
Written by Adam Gamba, Summer 2021

Single video informational card which links to its individual data
page
******************************************************************/

import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import "./Home.css";

// Info Card for individual video
const VideoCard = (props) => {
  // Example video: { id: 10, title: "A Simple Machine" }
  const video = props.video;

  // Two functions used to hash a string to a random color
  function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }
  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  }
  // Resulting hashed color
  let hashedColorString = intToRGB(hashCode(video.moduleTitle));

  return (
    <span>
      {/* Each video card links to its individual data page */}
      <Link to={`/videos/${video.moduleId}/${video.videoId}`}>
        <Card
          title={`${video.videoTitle} (ID ${video.videoId})`}
          hoverable
          headStyle={{
            backgroundColor: "#FAFAFA",
            textAlign: "center",
          }}
          style={{
            border: `7px ridge #${hashedColorString}8F`,
            boxShadow: "2px 2px 1px #888888",
            margin: "5px",
          }}
        >
          {props.displayModuleTitle
            ? `Module: ${video.moduleTitle}`
            : null}
        </Card>
      </Link>
    </span>
  );
};

export default VideoCard;
