/******************************************************************
StudentGrid.js
Written by Adam Gamba, Summer 2021

Renders grid of student cards
******************************************************************/

import StudentCard from "./StudentCard";
import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";

// Displays all available students in props.roster
const StudentGrid = (props) => {
  // Set number of columns based on screen width
  const NUM_OF_COLUMNS =
    window.innerWidth > 1000 ? 4 : window.innerWidth > 800 ? 3 : 2;

  let indivStudent = props.roster.filter(
    (x) => x.studentId === props.user.id
  )[0];
  // True if user is in roster (non-null filteredRoster) - used for
  // serach filtering
  let userInRoster = indivStudent ? true : false;
  return (
    // Renders list of students as a grid with NUM_OF_COLUMNS columns
    // Make sure NUM_OF_COLUMNS is integer divisible by 24 to work
    <Row>
      {/* Render student user on top row (if applicable) */}
      {!props.user.isContentCreator && userInRoster ? (
        <Col span={24}>
          <Link to={`/students/${props.user.id}`}>
            <Card
              title={`You: ${indivStudent.username}`}
              hoverable
              style={{
                border: `7px ridge #5bd1d7`,
                margin: "5px",
              }}
            >
              Hours Watched: {/* Display rounded # of hours */}
              {indivStudent.hours.toFixed(1) % 1 === 0
                ? Math.round(indivStudent.hours)
                : indivStudent.hours.toFixed(1)}
            </Card>
          </Link>
        </Col>
      ) : null}
      {props.roster
        // Filter out given user if they are the matching student
        .filter((student) => {
          // Example Student: {username: "vds@princeton.edu", studentId: 139, hours: 0}
          return (
            props.user.isContentCreator ||
            student.studentId !== props.user.id
          );
        })
        .map((selectedStudent, index) => {
          return (
            <Col span={24 / NUM_OF_COLUMNS}>
              <StudentCard key={index} student={selectedStudent} />
            </Col>
          );
        })}
    </Row>
  );
};

export default StudentGrid;
