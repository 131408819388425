/******************************************************************
IndividualStudentPage.js
Written by Adam Gamba, Summer 2021

Individual student data page, includes CheckboxGrid, and list of
StudentLineCharts. Private to admins and the given student
******************************************************************/

import React, { useState, useEffect } from "react";
import "./Home.css";
import { Spin, Empty, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import StudentLineChart from "./StudentLineChart";
import courseList from "../course_list.json";
import moment from "moment";
import CheckboxGrid from "./CheckboxGrid";

// Example student
// {username: "bw23@princeton.edu", studentId: 233, hours: 0}
const IndividualStudentPage = (props) => {
  const [student, setStudent] = useState(null);
  const [maxVideoDuration, setMaxVideoDuration] = useState(0);
  const [watchedVideos, setWatchedVideos] = useState([]);
  const [roster, setRoster] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValidUser, setIsValidUser] = useState(false);
  const [userIsLoaded, setUserIsLoaded] = useState(false);

  // Gets roster data from API
  // Runs function one time
  useEffect(() => {
    let studentId = parseInt(props.match.params.studentID);

    fetch("https://www.api.cubits.ai/api/v1/users/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((viewer) => {
        // Check if user is a content creator OR the correct student to
        // view this page
        if (
          (!viewer.hasOwnProperty("detail") ||
            viewer.detail !==
              "Authentication credentials were not provided.") &&
          (viewer.id === studentId || viewer.isContentCreator)
        ) {
          setIsValidUser(true);
        } else {
          setIsLoaded(true);
        }
        setUserIsLoaded(true);
      })
      .catch((error) => console.log(error));

    // Get data for roster
    let today = moment(new Date()).toISOString();
    let oneYearAgo = moment(
      new Date().setDate(new Date().getDate() - 365)
    ).toISOString();

    let coupon = "COS126SPRING2021";
    let url = `https://www.api.cubits.ai/api/v1/dashboard/videowatchdata/home-page-roster-activity/?after=${oneYearAgo}&before=${today}&coupon=${coupon}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => response.json())
      .then((x) => {
        setRoster(x);
      })
      .catch((error) => console.log(error));
  }, []);

  // Gets individual student activity and sets student
  // Runs only once roster has been updated
  useEffect(() => {
    if (roster.length === 0 || isLoaded || !isValidUser) return;
    let studentId = parseInt(props.match.params.studentID);
    // Find student with matching ID from roster
    let result = roster.filter((x) => x.studentId === studentId);
    if (result.length === 0) return;
    let indivStudent = result[0];
    setStudent(indivStudent);

    // Get data for individual student video activity
    let coupon = "COS126SPRING2021";
    // ! Temporary: classStartDate and classEndDate are one year ago and one year later, respectively
    let classStartDate = moment(
      new Date().setDate(new Date().getDate() - 365)
    ).toISOString();
    let classEndDate = moment(
      new Date().setDate(new Date().getDate() + 365)
    ).toISOString();
    let url = `https://www.api.cubits.ai/api/v1/dashboard/videowatchdata/individual-student-video-activity/?coupon=${coupon}&username=${indivStudent.username}&after=${classStartDate}&before=${classEndDate}`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let formattedVideos = {};
        // Returns maximum video duration from all data (used for
        // scaling purposes))
        let maximumVideoDuration = Object.values(data)
          .map((x) => x.videoDuration)
          .reduce((a, b) => {
            return Math.max(a, b);
          });

        for (let video in data) {
          // Example videoObj: {videoTitle: "Conditionals: The IF
          // Statement", moduleId: 8, collectionId: 5, data: Array(26)}
          let videoObj = data[video];
          videoObj.dataSum = videoObj.data.reduce((a, b) => a + b, 0);

          // Format videos array into object, with keys being module IDs
          // and values being an array of videos in that module
          let moduleId = videoObj.moduleId;
          if (formattedVideos.hasOwnProperty(moduleId)) {
            formattedVideos[moduleId].push(videoObj);
          } else {
            formattedVideos[moduleId] = [videoObj];
          }
        }
        setMaxVideoDuration(maximumVideoDuration);
        setWatchedVideos(formattedVideos);
        setIsLoaded(true);
      })
      .catch((error) => console.log(error));
  }, [roster, isValidUser]);

  if (localStorage.getItem("loggedIn") === "false") {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="Not authenticated. Please log in or sign up above." />
      </div>
    );
  } else if (!isLoaded || !userIsLoaded) {
    return (
      <div className="site-layout-content flex-center">
        <Spin size="large" />
      </div>
    );
  } else if (
    (!isValidUser && userIsLoaded && isLoaded) ||
    student === null
  ) {
    return (
      <div className="site-layout-content flex-center">
        <Empty description="Invalid User" />
      </div>
    );
  } else
    return (
      <div className="site-layout-content">
        <h1
          className="flex-center"
          style={{
            fontSize: "24pt",
            fontWeight: "bold",
            margin: "5px",
          }}
        >
          Welcome, {student.username}!
        </h1>
        <br />
        <h2 className="flex-center">
          Watch Time of Videos - {student.hours.toFixed(1)} Hours
          Watched
        </h2>

        {/* Grid of checkboxes or X's depending on which videos have been watched by the student */}
        <CheckboxGrid watchedVideos={watchedVideos} />

        <hr />
        {/* Header and tooltip info */}
        <div
          className="flex-center"
          style={{
            margin: "auto",
          }}
        >
          <h1
            style={{
              fontSize: "24pt",
              fontWeight: "bold",
              margin: "5px",
            }}
          >
            Watch Time Graphs
          </h1>
          <Tooltip
            placement="right"
            title="The following graphs show the amount of time invested in each minute of every video. The baseline represents what a graph would like if the user were to watch once at 1x speed. If the graph is above the baseline, then user has watched that section more than once or at < 1x speed. If the graph is below the baseline, then the user has watched that section at a speed > 1x."
          >
            <QuestionCircleOutlined
              style={{ fontSize: "200%", marginTop: "5px" }}
            />
          </Tooltip>
        </div>

        {/* Info now included in tooltip */}
        {/* <p className="flex-center">
          *Data measured in real-world time - not affected by watch
          speed (2x, 3x, etc.)
        </p>
        <p className="flex-center">
          **The baseline represents the graph if the student were to
          watch the video once at 1x speed
        </p> */}

        {/* Render modules AND videos as map of maps - allows us to render module title h1s between modules */}
        {Object.values(watchedVideos).map((module, i) => {
          return (
            <div>
              <h2 className="module-titles">
                Module {module[0].moduleId} -{" "}
                {
                  courseList.filter((x) => {
                    return x.moduleID === module[0].moduleId;
                  })[0].moduleTitle
                }
              </h2>
              <div>
                {module.map((selectedVideo, j) => {
                  // Example selectedVideo: {title: "Conditionals: The IF Statement", data: Array(26)}
                  return (
                    <div>
                      {/* Line Chart of student data for given video */}
                      <StudentLineChart
                        key={j}
                        maxVideoDuration={maxVideoDuration}
                        selectedVideo={selectedVideo}
                        width="80%"
                        height="200px"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
};

export default IndividualStudentPage;
